import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import moment from 'moment'
import initI18n from './i18n'
import i18n from 'i18next'
import { ThemeProvider } from 'styled-components'
import { theme } from './theme'
import { type ConfigInterface } from 'util/core/config/config.interface'
import { appConfig } from 'util/core/config/app-config.instance'
import { authService } from 'util/core/service/auth.service'
import TagManager from 'react-gtm-module'

void (async () => {
  const env = process.env.REACT_APP_ENV ?? 'dev'
  const envModule = await import(`./environments/environment.${env}`)
  const environment = envModule.environment as ConfigInterface

  const config: ConfigInterface = {
    production: environment.production,
    SEARCH_API: environment.SEARCH_API,
    USERS_API: environment.USERS_API,
    TRANSLATIONS_URL: environment.TRANSLATIONS_URL,
    PROZORRO: environment.PROZORRO,
    AUCTIONS_PROZORRO: environment.AUCTIONS_PROZORRO,
    PROZORRO_API_LINK: environment.PROZORRO_API_LINK,
    OLD_DZO: environment.OLD_DZO,
    FILES: environment.FILES,
    NEWS: environment.NEWS,
    CALLME: environment.CALLME,
    MESSAGES: environment.MESSAGES,
    defaultLanguage: environment.defaultLanguage,
    languages: environment.languages,
    ENABLE_GOOGLE_TAG_MANAGER: environment.ENABLE_GOOGLE_TAG_MANAGER,
    GOOGLE_TAG_ID: environment.GOOGLE_TAG_ID,
    ENABLE_MARKET: environment.ENABLE_MARKET
  }

  appConfig.load(config)

  await initI18n()

  await authService.init()

  moment.locale(i18n.language)

  const basename = (i18n.language === environment.defaultLanguage) ? '' : `/${i18n.language}`

  const app = <Suspense fallback={'...'}>
      <BrowserRouter basename={basename}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
  </Suspense>

  if (appConfig.get('ENABLE_GOOGLE_TAG_MANAGER')) {
    TagManager.initialize({ gtmId: appConfig.get('GOOGLE_TAG_ID') })
  }

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  )
  root.render(app)
  // ReactDOM.render(app, document.getElementById('root'));
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
