import styled from 'styled-components'
import { ExternalLink } from '../../external-link/external-link-component'
import { Link } from 'react-router-dom'

export const Wrap = styled.div`
  display: flex;
  gap: 40px;
  height: 100%;
  position: relative;
`
export const StyledLink = styled(Link)`
  height: 100%;
  img {
    height: 100%;
  }
`
export const StyledExternalLink = styled(ExternalLink)`
  height: 36%;
  position: relative;
  top: 50%;
  margin-top: -10px;
  @media screen and (max-width: ${props => props.theme.resolution.sm}) {
    height: 50%;
    margin-top: -8px;
  }
  img {
    height: 100%;
  }
`

export const Logo = styled.div`
  height: 36%;
  position: relative;
  top: 50%;
  margin-top: -10px;
  @media screen and (max-width: ${props => props.theme.resolution.sm}) {
    height: 50%;
    margin-top: -8px;
  }
  img {
    height: 100%;
  }
`
